import { useCallback, useEffect, useState } from 'react'
import FeedbackIcon from '../../icons/FeedbackIcon'
import HelpIcon from '../../icons/HelpIcon'
import NavLink from './NavLink'
import GlobalAddNew from '../GlobalAddNew/GlobalAddNew'
import SearchIcon from '../../icons/SearchIcon'
import { SearchAccountModal } from '../SearchAccount/SearchAccountModal'
import NotificationIcon from '../../icons/NotificationIcon'
import { useNotifications } from '@novu/notification-center'
import classNames from 'classnames'

type BottomHalfMenuProps = {
  expanded?: boolean
  isNotificationDrawerOpen?: boolean
  idForHelpButton?: string
  setIsNotificationDrawerOpen?: (isOpen: boolean) => void
}
export default function BottomHalfMenu({
  expanded,
  isNotificationDrawerOpen,
  idForHelpButton = 'zoom-chat-desktop',
  setIsNotificationDrawerOpen,
}: BottomHalfMenuProps) {
  const [openSearch, setOpenSearch] = useState(false)
  const notifications = useNotifications()

  const openSearchModal = useCallback(
    (e: any) => {
      if (e.metaKey && e.keyCode === 75) setOpenSearch(true)
    },
    [setOpenSearch]
  )

  useEffect(() => {
    document.addEventListener('keydown', openSearchModal)

    return () => document.removeEventListener('keydown', openSearchModal)
  }, [openSearchModal])

  return (
    <>
      <GlobalAddNew expanded={expanded} />
      <NavLink
        onClick={() => setOpenSearch(!openSearch)}
        as="button"
        icon={SearchIcon}
        className="h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem]"
        expanded={expanded}
        data-testid="search-button"
        data-tip="Search (⌘+k)"
        data-place="right"
        data-effect="solid"
        data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
      >
        Search
      </NavLink>
      <SearchAccountModal
        open={openSearch}
        onClose={() => setOpenSearch(false)}
      />
      {window.FEATURE_FLAGS.notifications && window.ENV.NOVU_APP_ID ? (
        <NavLink
          onClick={() => {
            setIsNotificationDrawerOpen?.(!isNotificationDrawerOpen)
          }}
          as="button"
          iconElement={
            <div className="relative" data-testid="notifications-button">
              <NotificationIcon className="h-12 min-h-[3rem] w-12 min-w-[3rem] stroke-vader p-2.5" />
              {notifications?.unseenCount > 0 && (
                <div
                  data-testid="notification-badge"
                  className={classNames(
                    'absolute bottom-2.5 right-2.5 h-3 w-3 rounded-full border-2 border-snowtrooper bg-error'
                  )}
                ></div>
              )}
            </div>
          }
          className="h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem]"
          expanded={expanded}
          data-testid="notifications-button"
          data-tip="Notifications"
          data-place="right"
          data-effect="solid"
          data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
        >
          Notifications
        </NavLink>
      ) : null}
      <NavLink
        as="button"
        icon={HelpIcon}
        className="open-intercom h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem]"
        expanded={expanded}
        data-testid="help-button"
        data-tip="Help"
        data-place="right"
        data-effect="solid"
        data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
        id={idForHelpButton}
      >
        Help
      </NavLink>
      <NavLink
        target="_blank"
        href="https://solflow.canny.io/feedback"
        rel="noopener noreferrer"
        data-testid="feedback-button"
        icon={FeedbackIcon}
        expanded={expanded}
        className="h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem]"
        data-tip="Feedback"
        data-place="right"
        data-effect="solid"
        data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
      >
        Feedback
      </NavLink>
    </>
  )
}
